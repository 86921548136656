$(document).ready(function() {
	var nav = $('#mainMenu');
	var lang_select = $('.locate-picker-wrapper');
	var bannerHeight = $('#hp_bannner').height();
	$(window).scroll(function() {
		var scroll = $(window).scrollTop();
		if (scroll > bannerHeight) {
			$(nav).addClass('scrolled');
			$(lang_select).show('200');
		} else {
			$(nav).removeClass('scrolled');
			$(lang_select).hide('200');
		}
	});

	// Scroll / load animation by GSAP
	/*let tl = gsap.Timeline({
		scrollTrigger {
			trigger: '.offer-section'
		}
	})*/

	//tl.to('')

	// On load banner animation
	var banner_decoration = $('.blue_banner_stuff');
	var banner_content_header = $('.banner-content h1');
	var banner_content_header_array = $('.banner-content h1').text().split(' ');
	var banner_content_text = $('.banner-content p');
	var banner_content_button = $('.banner-content a.btn');
	var video_decoration = $('.video-decoration');
	var video_image = $('.video-wrapper');
	var banner_socials = $('.banner-socials');
	var header = $('#mainMenu');

	// Add span to header array

	var textWSpan = '';
	banner_content_header_array.forEach(function(element) {
		textWSpan = textWSpan + '<span>' + element + '</span> ';
	});

	$(banner_content_header).html(textWSpan);

	var h1span1 = $('.banner-content h1 span:nth-child(1)');
	var h1span2 = $('.banner-content h1 span:nth-child(2)');
	var h1span3 = $('.banner-content h1 span:nth-child(3)');

	var tl = new TimelineMax();

	function myFunction(x) {
		if (x.matches) {
			// Mobile device anim  (max-width 767px);
			tl
				.fromTo(banner_content_header, 0.02, {opacity: 0}, {opacity: 1})
				.fromTo(video_image, 0.6, {opacity: 0, x: -110}, {opacity: 1, x: 0})
				.fromTo(video_decoration, 0.4, {opacity: 0, y: 100}, {opacity: 1, y: 0}, '=-.2')
				.fromTo(h1span1, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				.fromTo(h1span2, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				.fromTo(h1span3, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				//.fromTo(banner_decoration, 0.3, {opacity: 0, skewX: 0, x: -90}, {opacity: 1, skewX: -7, x: 0}, '=-.4')
				.fromTo(banner_content_text, 0.6, {opacity: 0, y: 60}, {opacity: 1, y: 0})
				.fromTo(banner_content_button, 0.3, {opacity: 0, x: 60}, {opacity: 1, x: 0}, '=-.2')
				.fromTo(banner_socials, 0.3, {opacity: 0, x: 60}, {opacity: 1, x: 0}, '=-.2');
			//.fromTo(mainMenu, 0.6, {opacity: 0, y: '-100%'}, {opacity: 1, y: 0});
		} else {
			// Desktop animation
			tl
				.fromTo(banner_content_header, 0.02, {opacity: 0}, {opacity: 1})
				.fromTo(h1span1, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				.fromTo(h1span2, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				.fromTo(h1span3, 0.5, {scale: 6, opacity: 0}, {scale: 1, opacity: 1})
				.fromTo(banner_decoration, 0.3, {opacity: 0, skewX: 0, x: -90}, {opacity: 1, skewX: -7, x: 0}, '=-.4')
				.fromTo(banner_content_text, 0.6, {opacity: 0, y: 60}, {opacity: 1, y: 0})
				.fromTo(video_image, 0.6, {opacity: 0, x: -110}, {opacity: 1, x: 0})
				.fromTo(video_decoration, 0.4, {opacity: 0, y: 100}, {opacity: 1, y: 0}, '=-.2')
				.fromTo(banner_content_button, 0.3, {opacity: 0, x: 60}, {opacity: 1, x: 0}, '=-.2')
				.fromTo(banner_socials, 0.3, {opacity: 0, x: 60}, {opacity: 1, x: 0}, '=-.2');
			//.fromTo(mainMenu, 0.6, {opacity: 0, y: '-100%'}, {opacity: 1, y: 0});
		}
	}

	var x = window.matchMedia('(max-width: 992px)');
	myFunction(x); // Call listener function at run time
	x.addListener(myFunction); // Attach listener function on state changes

	// Skew element while mouse is moving
	var $banner = $('#hp_bannner'),
		$elementToSkew = $('.video-wrapper'),
		rotateX = 0,
		rotateY = 1;

	$($banner).mouseover(function() {
		$(this).on('mousemove', function(event) {
			//console.log('pageX: ' + event.pageX + ', pageY: ' + event.pageY);
			rotateX = event.pageX / $banner.width();
			rotateY = event.pageY / $banner.width();
			let rotate = rotateY + rotateX - 5;
			//rotateY = (event.pageX + event.pageY) / 100;
			//console.log('X:' + rotateX + 'Y:' + rotateY);
			$($elementToSkew).css(
				'transform',
				'perspective( 300px ) rotateY(' + rotate + 'deg)'
				//'perspective( 300px ) rotateY(' + rotateY + 'deg) rotateX(' + rotateX + 'deg)'
			);
		});
	});

	// Homepage events filtering
	$('.events-wrapper-hp').slick({
		infinite: false,
		slidesToShow: 3,
		prevArrow: '<span class="arrow-prev"></span>',
		nextArrow: '<span class="arrow-next"></span>',
		dots: true,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 556,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('#events-filter-hp div[data-filter]').click(function(e) {
		e.preventDefault();
		$('#events-filter-hp div[data-filter]').removeClass('filter-active');
		$(this).addClass('filter-active');
		var activeFilter = $(this).data('filter');
		$('.events-wrapper-hp').slick('slickUnfilter');
		$('.events-wrapper-hp').slick('slickFilter', '.' + activeFilter);
		if (activeFilter == 'all') {
			$('.events-wrapper-hp').slick('slickUnfilter');
		}
	});

});



